import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import StaffingHeader from "../../molecules/staffing/staffingHeader";
import bannerImg from "../../images/servicesassets/costing.png";

import StaffingDescription from "../../molecules/staffing/staffingDescription";
import WhyNeedsCost from "../../molecules/services/costeng/WhyNeedsCost";

import { useStaticQuery, graphql } from "gatsby";
import IndustrySlider from "../../molecules/Industry/IndustrySlider";
import CostReduction from "../../images/servicesassets/costreduction.png";

//video
// import CostEngineeringVideo from "../../videos/CostEngineeringVideo.mp4";

import StaffingNewTimelineComponent from "../../molecules/staffing/staffingNewTimelineComponent";
import HeaderWithVideo from "../../molecules/HeaderWithVideo";
import ImageBanner from "../../components/atoms/banner/imageBanner";
// import {  } from "gatsby";

const Costengineering = () => {
  const data = useStaticQuery(graphql`
    query GetCostingData {
      gcms {
        solutionsPages(where: { solutionsPageType: COSTENG }) {
          heading
          paragraph
          headerVideo {
            url
          }
          solutionsPageSlides {
            paragraph
            slideHeading
            listItems
            url
            slideImage {
              url
            }
          }
        }
      }
    }
  `);
  // const timeline = [];
  const Capabilities = [];
  const timeline = [
    {
      heading: "Receive Sourcing Request From Buyer",
      paragraph: "",
      isList: false,
      listItems: "",
    },
    {
      heading: "Input – 3D Model and Drawing",
      paragraph: "",
      isList: false,
      listItems: "",
    },
    {
      heading: "Route Manufacturing Process ",
      paragraph: "",
      isList: false,
      listItems: "",
    },
    {
      heading: "Prepare Should Cost Model & Report",
      paragraph: "",
      isList: false,
      listItems: "",
    },
    {
      heading: "Negotiate With Supplier Based On Gap Analysis",
      paragraph: "",
      isList: false,
      listItems: "",
    },
    {
      heading: "Provide Approval For Sourcing",
      paragraph: "",
      isList: false,
      listItems: "",
    },
  ];
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/services/costengineering/"}
        title={"Cost Engineering Services - Goken "}
        pageName={"Cost Engineering"}
        description={`Explore top-tier cost engineering services at Goken. Achieve budget efficiency and value in your engineering projects. Click to find out how!`}
        keywords={
          "cost optimization, should cost, make vs buy, spend analysis, cost reduction, cost insights, cost teardown, competition, supplier negotiation, specific cost models"
        }
        // meta="Automation, KBE, knowledge based engineering, C++, Automation Services, RPA, automation studio, RPA Automation, automation technology, automation"
        facebookTitle={
          "Goken - Cost Engineering Company in USA & India | Product Development"
        }
        facebookDescription={
          'Goken Cost Engineering Services in USA, and India has the core competence in "Should Cost" which provides "Cost Insights" i.e. a combination of material cost, process cost, overheads, and profit.​ '
        }
      />
      <HeaderWithVideo
        headingOne="Cost Engineering"
        btnTxt="Get in Touch"
        para={`Our core competence in cost engineering lies in Should Costing. "Should Cost" is the optimum cost a product "should" incur to a company considering the best available manufacturing & regional processes. It identifies all significant cost drivers and provides "Cost Insight" for essential decisions during sourcing and manufacturing. These insights comprise of material cost, process cost, overheads, and profit.​`}
        video={data.gcms.solutionsPages[0].headerVideo}
        url="/contactus/"
      />
      {/* <StaffingHeader
        heading={"Cost Engineering"}
        paragraph={`Our core competence in cost engineering lies in Should Costing. "Should Cost" is the optimum cost a product "should" incur to a company considering the best available manufacturing & regional processes. It identifies all significant cost drivers and provides "Cost Insight" for essential decisions during sourcing and manufacturing. These insights comprise of material cost, process cost, overheads, and profit.​`}
        image={bannerImg}
        btnTxt="Get In Touch"
      ></StaffingHeader> */}
      <WhyNeedsCost />
      {/* <StaffingDescription
        headingOne={`Scope of Should Cost Analysis during Product Life cycle`}
        description={
          "required short paragraph telling the information about header."
        }
        image={ScopeImage}
      /> */}
      {/* <StaffingTechnicalCapabilities /> */}

      <StaffingDescription
        headingOne={"How does should cost help in Supplier Negotiation?"}
        description={
          "Should Cost is a powerful fact-based negotiation tool. When used by the right experts in the industry, it provides information that can potentially save millions of dollars. Through our experience working with clients, we have seen that the initial quote provided by the supplier is substantially higher (first bar in the chart). Our experts provide detailed “Should Cost” analysis to our clients that help them realize potential savings opportunity (marked as Leverage of ¥ 500 per component). With this leverage on their side, our client often go into the meeting with supplier with holistic information and can save part of the leverage through fact-based negotiations(Savings of ¥350 shown here). The final quote arrived through such information based discussion is always mutually rewarding for the client as well as their suppliers."
        }
        image={CostReduction}
      />

      <StaffingNewTimelineComponent
        // style={"noParagraph"}
        items={timeline}
        heading="OUR PROCESS"
        bannerService={"Cost Engineering"}
        removeLastSection={true}
        paragraph={`Goken supports clients at every step of the Should Cost process” Reach out to learn more about our expertise in Should Cost.​`}
      />
      <IndustrySlider
        SliderItems={data.gcms.solutionsPages[0].solutionsPageSlides}
        heading="Cost Engineering"
        hideDefaultTitle={true}
        hideExplore={true}
        customTitle={"Our services specially designed for Cost Engineering"}
        hideButtons={true}
        hideUrl={true}
      ></IndustrySlider>
      <ImageBanner
        heading={`INTERESTED TO LEARN MORE ABOUT COST ENGINEERING?`}
        btnText="Get in Touch"
        link="/contactus/"
      />
    </Layout>
  );
};
export default Costengineering;
